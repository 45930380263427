//import config from 'config';
// import React from 'react';
import * as Constant from '.././Constant/Constant';
export const AuthService = {
    login,
    logout
};

function login(user_name, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_name, password })
    };
    
    return fetch(Constant.API_BASE_URL+'login', requestOptions)
        .then(handleResponse)
        .then(user => {
            if (!user.error) { 
                localStorage.removeItem(Constant.LOCAL_STORAGE_USER_KEY);
                localStorage.setItem(Constant.LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
            }
            return user;
        });
}

function logout() {
    localStorage.removeItem(Constant.LOCAL_STORAGE_USER_KEY);
    return [];
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
//        if (!response.ok) { 
//            if (response.status === 401) {console.log('112');
//                // auto logout if 401 response returned from api
//                logout();
//                //location.reload(true);
//            }
//            const error = (data && data.message) || response.statusText;
//            return Promise.reject(error);
//        }

        return data;
    });
}