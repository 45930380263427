//import config from 'config';
import { AuthHeader } from '.././helpers/AuthHeader';
import * as Constant from '.././Constant/Constant';

export const PolicyService = {
    getPolicyDetails,
    getPolicyDocZip
};

function getPolicyDetails(registrationNumber, policyNo,) {
    var filters=JSON.stringify({ 'registrationNumber':registrationNumber, 'policyNo':policyNo,'status_id':84 });
    const requestOptions = {
        method: 'GET',
        headers: AuthHeader()
    };
    return fetch(Constant.API_BASE_URL+'insucdtickets/getbookedpolicy?filters='+filters, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
function getPolicyDocZip(policyId) {
     const requestOptions = {
        method: 'GET',
        headers: AuthHeader()
    };
    return fetch(Constant.API_BASE_URL+'getZipFileByPolicyId/'+policyId, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                //logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}