import React from 'react';
import loader from '.././loader.gif';
function Loader() {
  return (
    <div className="loader center text-center">
      <img src={loader} />
    </div>
  );
}

export default Loader;