import React from 'react';


function Pagenotfound() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
         Sorry, page Not Found. 
        </p>
      </header>
    </div>
  );
}

export default Pagenotfound;
