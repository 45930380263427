import React, { useState } from "react";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { AuthService } from '.././services/AuthService';
import '.././index.css';
import inslogo from '.././inslogo.png';


export default function Login(props) {
  const [user_name, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errormsg, setErrorMsg] = useState("");
  
  function validateForm() {
    return user_name.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
        if (!(user_name && password)) {
            return;
        }
        AuthService.login(user_name, password)
            .then(
                user => { 
                    if (!user.error) { 
                        props.history.push("/insurance");
                    }else{
                        setError(true);
                        setErrorMsg(user.error[0].message);
                    }
                }
            );
  }
  return (
    <div className="App-header">
    <div class="card col-md-4">
        <div class="card-header" style={{paddingTop:'20px',textAlign:"center"}}>
        <img src={inslogo} />
        </div>
            <div class="card-body">
              <div class="row">                
                <div class="col-md-12">
                    <header>
                         {
                            error && <div style={{color: `red`}}>{errormsg}</div>
                          }
                      <form onSubmit={handleSubmit}>
                        <FormGroup controlId="email">
                          loginId
                          <FormControl
                            autoFocus
                            type="text"
                            value={user_name}
                            onChange={e => setUserName(e.target.value)}
                          />
                        </FormGroup>
                        <FormGroup controlId="password">
                          Password
                          <FormControl
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type="password"
                          />
                        </FormGroup>
                        <Button block disabled={!validateForm()} type="submit">
                          Login
                        </Button>
                      </form>
                   
              </header>
              </div>
            </div>
          </div>
    </div> 
  </div>
  );
}
