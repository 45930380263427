import React from 'react'
import { AuthService } from '.././services/AuthService';
import { Link } from 'react-router-dom';
import inslogo from '.././inslogo.png';
function MainPanel(props) {
    
    function logoutfromapp(event) {
        AuthService.logout();
        return true;
    }
  return (
<div className="main-panel">
     
      <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
        <div className="container-fluid">
          <div className="navbar-wrapper">
            <div className="navbar-brand"><img src={inslogo} /></div>
          </div>
         
          <div className="collapse navbar-collapse justify-content-end">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <Link onClick={logoutfromapp} to='/login' className="nav-link">                                  
                    Logout                 
                </Link>         
              </li>
            </ul>
          </div>
        </div>
      </nav>
     
      <div className="content">
        <div className="container-fluid">
          <div className="card">
            
            {props.children}
            
          </div>
        </div>
      </div>
      <footer className="footer">
        
      </footer>
    </div>
     )
    }
    export default MainPanel;
