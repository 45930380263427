import React from 'react';


function Leftnav() {
  return (
     <div className="sidebar">
      <div className="logo">
          Document Manager
       </div>
      <div className="sidebar-wrapper">
        <ul className="nav">
          <li className="nav-item active ">
            <div className="nav-link">             
              Insurance Policy
            </div>
          </li>
        </ul>
      </div>
    <div className="sidebar-background"></div></div>
   
  );
}

export default Leftnav
    ;
