import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route,  BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import Login from './Pages/Login';
import Policy from './Pages/Policy';
import Pagenotfound from './Pages/Pagenotfound';
import PrivateRoute from './helpers/PrivateRoute';

import * as serviceWorker from './serviceWorker';

//ReactDOM.render(<Login />, document.getElementById('root'));

const routing = (
  <Router>
    <div>
      <PrivateRoute path="/404" component={Pagenotfound} />
      <PrivateRoute path="/insurance" component={Policy} />
      <Route exact path="/" component={Login} />
      <Route path="/login" component={Login} />
    </div>
  </Router>
)
ReactDOM.render(routing, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
