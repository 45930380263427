import React, { useState } from "react";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { PolicyService } from '.././services/PolicyService';
import Leftnav from './Leftnav'
import MainPanel from './MainPanel'
import '.././index.css';
import {FaCloudDownload} from 'react-icons/lib/fa'
import Loader from './Loader'

export default function Policy(props) {
  const [registrationNumber, setRegNumber] = useState("");
  const [policyNo, setPolicyNumber] = useState("");
  const [data, setData] = useState([]);
  const [nodata, setNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [downLoading, setIsDownLoading] = useState(false);
  function validateForm() { if(isLoading){return  false;}
    return registrationNumber.length > 7 || policyNo.length > 0;
  }
  function resetFilter(event){
       event.preventDefault();
       setRegNumber("");
       setPolicyNumber("");
       setData([]);
  }
  function getPolicys(event) {
    event.preventDefault();
         // stop here if form is invalid
        if (!(registrationNumber || policyNo)) {
            return;
        }
        setData([]);
        setIsLoading(true);
        PolicyService.getPolicyDetails(registrationNumber, policyNo)
            .then(
                data => { 
                  setIsLoading(false);
                  if(!data.error){
                        if(data.tickets.length){
                            setNoData(false);
                            setData(data.tickets);
                        }else{
                            setData([])
                            setNoData(true);
                        }
                    }else{
                        setData([])
                    }
                }
            );
  }
  
  function downloaddoczip(event){
        
        var policyId=event.currentTarget.dataset.id;
        if (!(policyId) || downLoading) {
            return;
        }
        setIsLoading(true);
        setIsDownLoading(true);
        PolicyService.getPolicyDocZip(policyId)
        .then(
            data => { 
                setIsLoading(false);
                setIsDownLoading(false);
                if(data.msg==='Doc Found'){
                    window.open(data.url);
                }else{
                    alert('Doc Not Found');
                }
            }
        );
  }
  
    
    return (
        <div className="wrapper">
        <MainPanel>
          <div className="container">
              <form  className="row" onSubmit={getPolicys}>
              <div className="col-sm-4">
                <FormGroup controlId="reg_no">
                <label> Regitration Number</label>
                  <FormControl
                    autoFocus
                    type="text"
                    value={registrationNumber}
                    onChange={e => setRegNumber(e.target.value)}
                  />
                </FormGroup>
                </div>
                 <div className="col-sm-4">
                <FormGroup controlId="policy_num">
                <label> Policy Number</label>
                  <FormControl
                    value={policyNo}
                    onChange={e => setPolicyNumber(e.target.value)}
                    type="text"
                  />
                </FormGroup>
                </div>
                
                 <div className="col-sm-2">
                  <label>&nbsp;</label>
                        <Button block disabled={!validateForm()} type="submit">Search</Button>
                  </div>
                  <div className="col-sm-2">
                  <label>&nbsp;</label>
                        <Button block className="btn-secondary" onClick={resetFilter}>Reset</Button>
                  </div>
                  
              </form>
              
           
            
                <div className="row">

                <div className="table-responsive">
                <table className="table table-striped table-bordered table-hover dataTables-example dataTable">
                        <tr>
                          <th>Registration Number</th>
                          <th>Policy Number</th> 
                          <th>Policy Issuance Date</th>
                          <th>Case Type</th>
                          <th>Vehicle Type</th>
                          <th>Policy Cover</th>
                          <th>Download Zip</th>
                        </tr>
                        {data.map(item => (
                            <tr key={item.policyId}>
                                <td>{item.registrationNo}</td>
                                <td>{item.policyNumberSearch}</td>
                                <td>{item.bookingDate}</td>
                                <td>{item.caseType}</td>
                                <td>{item.vehicleType}</td>
                                <td>{item.policyType}</td>
                                <td onClick={downloaddoczip} data-id={item.policyId} style={{cursor:'pointer',fontSize:'16px', textAlign:'center', color:'#f34653'}}> <FaCloudDownload/> </td>
                            </tr>
                          ))}
                          {
                          nodata && <div>No Data Found</div>
                          }
                          
                      </table>
                      {
                            isLoading && <Loader/>
                      }
                      </div>
                </div>
            
            </div>
            </MainPanel>
            </div>
  );
  }
